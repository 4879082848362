import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';
import Slider from 'react-slick';
import SEO from '../components/seo';
import { CheckCircle } from 'react-feather';

const Heading = styled.h1`
  margin-bottom: 0.25em;
`;
const Subheading = styled.p`
  margin-bottom: 2em;
`;
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`;

const ServicesList = (props) => {
  let { slug, location, locationslug, serviceTitle } = props;
  return (
    <li>
      <Link to={'/' + slug + '-' + locationslug + '/'} title={serviceTitle}>
        {serviceTitle}
      </Link>
    </li>
  );
};

const CitiesServicesList = (props) => {
  let { location, slug, services, marketSegment = [] } = props;

  return (
    <li className="w-1/2 md:w-1/3 flex py-2">
      <div className="flex-shrink-0 mr-2">
        <CheckCircle fontWeight={900} strokeWidth={3} size={22} className="text-brand-500 mt-1" />
      </div>{' '}
      <span>
        <h3 className="text-neutral-800 underline-none font-semibold font-display text-lg">{location}</h3>
        <ul>
          {services.edges.map((item, i) => {
            return marketSegment.map((marketSeg) => {
              let cleanReplaceServiceTitle = item.node.serviceTitle.replace(/{{city}}/g, location);
              return (
                <ServicesList
                  key={item.node.id}
                  locationslug={slug}
                  location={location}
                  serviceTitle={cleanReplaceServiceTitle}
                  slug={item.node.slug}
                />
              );
            });
          })}
        </ul>
      </span>
    </li>
  );
};

const ServiceArea = (props) => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              slug
              marketSegment
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
              content {
                id
                content
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout pageProps={props}>
          <SEO
            title={'Top Tier Roofing Services in Jefferson, Georgia & Surrounding Areas'}
            description={
              'Discover premier roofing solutions with Top Tier Roofing in Jefferson, GA. Offering expert services in repairs, installations, and maintenance across multiple areas. Your top choice for quality and reliability.'
            }
          />
          <div className="  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
            <h1 className="md:text-4xl font-semibold leading-10 text-3xl">
              Top Tier Roofing: Serving Jefferson, Georgia and Beyond
            </h1>
            <p className="text-neutral-700 leading-6 max-w-3xl">
              Welcome to Top Tier Roofing, your premier roofing service provider in Jefferson, Georgia, and surrounding
              communities. Our commitment to excellence and customer satisfaction has established us as a leading name
              in the roofing industry. We are dedicated to offering top-notch roofing services tailored to meet the
              unique needs of each client.
            </p>
            <h2 className="md:text-2xl font-semibold leading-10 text-xl">Our Service Areas</h2>
            <p className="text-neutral-700 leading-6 max-w-3xl">
              At Top Tier Roofing, we pride ourselves on our extensive service coverage, ensuring that quality roofing
              solutions are accessible to as many homeowners and businesses as possible. Our primary service areas
              include:
            </p>

            <div className="flex flex-wrap mt-4">
              {data.allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <CitiesServicesList
                    key={item.node.id}
                    location={item.node.location}
                    slug={item.node.slug}
                    marketSegment={item.node.marketSegment}
                    services={data.allContentfulServiceAreaContent}
                  />
                );
              })}
            </div>

            <h2 className="md:text-2xl font-semibold leading-10 text-xl"> Get in Touch</h2>
            <p className="text-neutral-700 leading-6 max-w-3xl">
              Ready to experience the Top Tier difference? Contact us today to schedule a consultation or to learn more
              about our services. Our team is eager to assist you in protecting and enhancing your property with
              top-quality roofing solutions.
              <br />
              <br />
              At Top Tier Roofing, we're not just building roofs; we're building relationships. Join our family of
              satisfied customers and discover the peace of mind that comes with a secure, reliable roof over your head.
            </p>
          </div>
        </Layout>
      );
    }}
  />
);

export default ServiceArea;
